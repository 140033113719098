<template>
  <div class="row justify-content-center">
    <share-facebook
      :page_url="url"
      title_social="Facebook"
      has_icon
      has_counter
    />
    <share-twitter
      :page_url="url"
      title_social="Twitter"
      has_icon
      has_counter
    />
    <share-whatsapp
      :page_url="url"
      title_social="WhatsApp"
      has_icon
      has_counter
    />
    <share-telegram
      :page_url="url"
      title_social="Telegram"
      has_icon
      has_counter
    />
  </div>
</template>

<script>
const ShareFacebook = () => import(/* webpackPrefetch: true */ 'vue-goodshare/src/providers/Facebook')
const ShareTwitter = () => import(/* webpackPrefetch: true */ 'vue-goodshare/src/providers/Twitter')
const ShareWhatsapp = () => import(/* webpackPrefetch: true */ 'vue-goodshare/src/providers/WhatsApp')
const ShareTelegram = () => import(/* webpackPrefetch: true */ 'vue-goodshare/src/providers/Telegram')

export default {
  name: 'share-bar',
  components: {
    ShareFacebook,
    ShareTwitter,
    ShareWhatsapp,
    ShareTelegram,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
}
</script>
